<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Zamówienia</b> w tym miejscu znajdują się dokumenty zamówień od
        klientów.
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview title="Zamówienia" v-if="state === 'loaded'">
          <template v-slot:preview>
            <div>
              <b-row>
                <b-col></b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filtuj"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Szukaj..."
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Wyczyść</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                responsive
                striped
                :items="orders.items"
                :fields="orders.fields"
                :filter="filter"
                :tbody-tr-class="rowClass"
              >
                <template #cell(actions)="row">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-1"
                    @click="getDetails(row.item)"
                  >
                    <b-icon icon="pencil" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
        </KTCodePreview>
        <div class="d-flex justify-content-center mb-3" v-else>
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
    </div>

    <template>
      <!-- details modal  -->
      <b-modal
        size="xl"
        scrollable
        ref="orderDetailModal"
        :title="order.fullNumber"
      >
        <div class="d-block text-center"></div>

        <b-container>
          <div class="row">
            <div class="col-md-6">
              <b-list-group>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6 bold">
                      <b>Status:</b>
                    </div>
                    <div class="col-md-6">
                      <b-badge variant="success">{{ order.status }}</b-badge>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      <b>Data zamówienia:</b>
                    </div>
                    <div class="col-md-6">
                      {{ order.date }}
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      <b>E-mail:</b>
                    </div>
                    <div class="col-md-6">
                      {{ order.erpUser.email }}
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div class="col-md-6">
              <b-list-group>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      <b>Akronim:</b>
                    </div>
                    <div class="col-md-6">
                      <b-badge>{{ order.erpUser.acronym }}</b-badge>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      <b>Imię nazwisko:</b>
                    </div>
                    <div class="col-md-6">
                      {{ order.erpUser.person }}
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      <b>Telefon:</b>
                    </div>
                    <div class="col-md-6">
                      {{ order.erpUser.phone }}
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
          <hr />
          <div v-if="order.name.length > 0">
            <b-button v-b-toggle.collapse-delivery size="sm" variant="primary"
              >Dane dostawy <b-icon icon="truck"></b-icon
            ></b-button>
            <b-collapse id="collapse-delivery" class="mt-2">
              <b-card>
                {{ order.name }} <br />
                {{ order.street }} <br />
                {{ order.postal }} {{ order.locality }}
              </b-card>
            </b-collapse>
            <hr />
          </div>
        </b-container>
        <b-table
          responsive
          striped
          bordered
          hover
          :fields="orderElements.fields"
          :items="orderElements.items"
        >
        </b-table>
        <div class="d-block text-right">
          <h2>
            <b-badge variant="warning"
              >koszt razem: {{ totalPointCost }} pkt</b-badge
            >
          </h2>
          <b-badge variant="secondary"
            >forma płatności: {{ order.paymentForm }}</b-badge
          >
        </div>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button
            variant="success"
            size="sm"
            class="float-right"
            @click="confirmOrder"
            v-if="order.status == 'ZŁOŻONE'"
          >
            Potwierdź zamówienie
          </b-button>
          <b-button
            variant="outline-secondary"
            size="sm"
            class="float-right"
            @click="hide()"
          >
            Zamknij
          </b-button>
        </template>
      </b-modal>
      <!-- end of details modal -->
    </template>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import KTCodePreview from "@/view/content/CodePreview";

export default {
  name: "Orders",
  data() {
    return {
      state: "loading",
      orders: {
        fields: [
          {
            key: "fullNumber",
            label: "Numer zamówienia",
            sortable: true
          },
          {
            key: "erpUser.acronym",
            label: "Klient",
            sortable: true
          },
          {
            key: "date",
            label: "Data",
            sortable: true
          },
          {
            key: "status",
            label: "Status",
            sortable: true
          },
          {
            key: "actions",
            label: "Akcje",
            thClass: "action-column"
          }
        ],
        items: []
      },
      orderElements: {
        fields: [
          {
            key: "erpPrize.code",
            label: "Kod",
            sortable: true
          },
          {
            key: "erpPrize.name",
            label: "Nazwa",
            sortable: true
          },
          {
            key: "cost",
            label: "Koszt [pkt]",
            align: "end",
            sortable: true
          },
          {
            key: "quantity",
            label: "Ilość",
            align: "end",
            sortable: true
          }
        ],
        items: []
      },
      filter: null,
      order: {
        fullNumber: "",
        date: "",
        status: "",
        erpUser: {},
        paymentForm: "",
        name: "",
        stree: "",
        postal: "",
        locality: ""
      }
    };
  },
  components: {
    KTCodePreview
  },
  methods: {
    getOrders() {
      ApiService.get("/all-orders").then(response => {
        this.orders.items = response.data;
        this.state = "loaded";
      });
    },

    getDetails(record) {
      this.orderElements.items = record.elements;
      this.order = record;
      this.$refs.orderDetailModal.show();
    },

    confirmOrder() {
      const formData = new FormData();
      formData.append("id", this.orderElements.items[0].orderId);

      ApiService.post("/order-status", formData, {
        headers: { "Content-Type": "application/json" }
      }).then(() => {
        this.state = "loading";
        this.$refs.orderDetailModal.hide();
        this.getOrders();
      });
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "ZŁOŻONE") return "table-warning";
      if (item.status === "POTWIERDZONE") return "table-success";
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Administrator" },
      { title: "Zamówienia" },
      { title: "" }
    ]);

    this.getOrders();
  },
  computed: {
    totalPointCost: function() {
      return this.orderElements.items.reduce(
        (previous, current) =>
          previous + current.quantity * current.cost,
        0
      );
    }
  }
};
</script>
